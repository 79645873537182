import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const styles = css`
  .section {
    background-color: ${COLORS.black100};
    .section-inner {
      width: 100%;
      padding: 32px 16px;
      margin: auto;
      @media screen and (min-width: ${BREAKPOINT.desktop}px) {
        max-width: 1080px;
      }
      .title {
        text-align: center;
        margin: 0 auto 24px;
        width: max-content;
        .divider {
          background-color: ${COLORS.primary};
          height: 3px;
          margin: 16px auto 0;
        }
      }
    }
  }

  .swiper-container {
    margin-top: 24px;
    @media screen and (min-width: ${BREAKPOINT.desktop}px) {
      margin-top: 40px;
      :global(.swiper-button-prev),
      :global(.swiper-button-next) {
        display: none;
      }
    }
  }
  .signup-campaign-button {
    margin: 24px auto 0;
    padding-bottom: 16px;
    white-space: nowrap;
    width: 343px;
    :global(.button) {
      height: 64px;
      border-radius: 32px;
    }
  }
  .section.testimonial {
    :global(.testimonial-slider) {
      background: none !important;
      margin: 0 !important;
      padding: 0 !important;
      :global(.testimonial-slider-container) {
        padding: 0 !important;
      }
    }
  }
  :global(.top-faq-wrapper) {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

export default styles;
